<template>
    <router-link to="/men" style="display:block;margin:80px auto 24px;width:100%;max-width:800px;">&lt; Back to your Real Man Army list</router-link>
  <div style="text-align: center">
    <h1 class="toppertitle">Welcome to<br>the Dungeon</h1>
    <div>
      <p>Pay $GODS for entry.</p>
      <p>No healing.</p>
      <p>Winner takes all.</p>
    </div>
    <div>
      <h1 style="padding: 64px 0 0;">Dungeon 1:<br><span style="color:#f00;">&#10084;alentine's Day Massacre</span></h1>
      <p>Opens February 14</p>
    </div>
  </div>

</template>

<script>
export default {
  name: "Dungeon"
}
</script>

<style scoped>

</style>