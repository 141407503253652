<template>
  <router-link to="/men" style="display:block;margin:120px auto 0;width:100%;max-width:800px;">&lt; Back to your Real Man Army list</router-link>
  <div style="max-width: 600px; margin: 100px auto 150px;">
    <h1 class="toppertitle">Recruitment</h1>
    <img src="/img/sad.jpg">
    <p>Real Men don't like to fight, that's just a fact of life.</p>
    <p>So if you want to recruit one to enter the RMFC, you have two options...</p>
    <p>Head over to <a href="https://tokentrove.com/collection/GodsUnchainedCards?search=A%20Real%20Man" target="_blank">TokenTrove</a> and buy some, or...</p>
    <div>See if you can win one on <a target="_blank" href="https://guslots.net/">GUSlots</a>!</div>

  </div>
</template>

<script>
export default {
  name: "ManRaffle"
}
</script>

<style scoped>
img{
  display: block;
  padding: 0;
  margin: 0 auto 36px ;
  border-radius: 8px;
}
</style>